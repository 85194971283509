import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

import image1 from '../images/Image.jpeg';
import image2 from '../images/Image2.jpeg';

const Adultes = ({ data }) => {
  const quote1 = <><div style={{ color:"orange" }}>"Dans mes yeux, tu liras tes propres peurs, dans mes attitudes, tes incertitudes.</div></>;
  const quote2 = <><div style={{ color:"orange" }}>Lorsqu'enfin devant toi tu verras un cheval serein, c'est que tu te seras révélé à toi même"</div></>;
  const quote3 = <><div style={{ color:"orange" }}>Auteur inconnu</div></>;
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;
  
  return (
    <Layout>
      <SEO title="Adultes" />
      <Header metadata={data.site.siteMetadata} />

<img width={450} height={600} src={image1} alt=""/>
<br/><br/>
{prereq}
<br/><br/>
Soutenue par le cheval, je vous accompagne dans la résolution de vos difficultés de vie.<br/>
Quelle que soit votre problématique l’équicoaching et surtout le cheval seront là pour vous aider à trouver des solutions et progresser.<br/><br/>
Mon offre: <br/>
<br/>
<b>Equi-Trauma: </b><br/>
Je prends conscience et j’apprends à vivre avec mes traumas (abus sexuel, choc émotionnel, naissance difficile, maltraitance).<br/><br/>

<b>Equi-Émotions: </b><br/>
Je dépasse mes angoisses et j'atteins la sérénité. <br/>
J’abandonne mes peurs inexpliquées pour de l’apaisement. <br/>
Je sors de la colère qui est en moi pour aller vers la paix. <br/>
Je quitte la peur des jugements en faveur de l’amour de soi.<br/><br/>

<b>Equi-Blocage: </b><br/>
Je passe de la sensation de blocage au mouvement, des croyances limitantes à la pensée positive, de la procrastination à l’action, de l’envie de changement à l’engagement.<br/><br/>

<b>Equi-Systémique: </b><br/>
Je trouve enfin ma place. <br/>
Mes douleurs chroniques disparaissent.<br/>
La répétition de schémas indésirables s'atténue.<br/><br/>

<b>Equi-Burn-out: </b><br/>
J’apprends à connaître mon corps et je sors de l'épuisement.<br/><br/>

Je propose des coachings d'environ 1h30 à 2 heures. Le nombre de séances varie en fonction de la problématique mais généralement 5 à 6 séances suffisent, à raison d' une par mois. Pour un burn-out ou une dépression, compter 6 à 7 séances.<br/>

<br/><br/>
    
    {quote1}
    {quote2}
    {quote3}

    <br/>
    <img width={450} height={600} src={image2} alt=""/>
    </Layout>
  );
};

export default Adultes;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
